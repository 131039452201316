const convertAnswer = (val) => {
  const yes = ['Yes', 'হ্যাঁ', 'ஆம்', 'Ada', '有', 'ဟုတ္ကဲ႕', 'เคย']
  const no = ['No', 'না', 'இல்லை', 'Tidak Ada', '没有', 'မဟုတ္ပ', 'ไม่เคย']
  const dont = ['Don\'t know', 'অজ্ঞাত', 'অজ্ঞাত', 'Tidak Tahu', '不知道', 'မသိပါ', 'ไม่ทราบ', 'Unknown']

  for (let i = 0; i <= yes.length - 1; i++) {
    if (yes[i] === val) {
      return yes[0]
    }
  }

  for (let i = 0; i <= no.length - 1; i++) {
    if (no[i] === val) {
      return no[0]
    }
  }

  for (let i = 0; i <= dont.length - 1; i++) {
    if (dont[i] === val) {
      return dont[0]
    }
  }
}

const removeSpaceString = (str) => {
  return str.replace(/\s/g, ""); //delete all space
  // return str.replace(/\s+/, " ");    // delete space more than 1
}

function formatWeight(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : "")
    );
  } catch (error) {
    alert(error || error?.message)
  }
}

const LIMIT_PAGE = 20
const LIMIT_PAGE_MODAL = 5
const LIMIT_PAGE_MAX = 999999999

export const capitalizeFirstLetter = (string) => {
  if (!string || string === "") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validateEmailWithoutSpecial = (email) => {
  if (!email || email === "") {
    return "";
  }
  let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(email);
};

export const validatePassword = (password) => {
  if (!password || password === "") {
    return "";
  }
  let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
  return re.test(password);
};

export const convertUTCTimeToLocalTime = (UTCDateString) => {
  var convertdLocalTime = new Date(UTCDateString);

  var hourOffset = convertdLocalTime.getTimezoneOffset() / 60;

  convertdLocalTime.setHours(convertdLocalTime.getHours() + hourOffset);

  return convertdLocalTime;
};

export const formatMoney = (num) => {
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatHNG = (num) => {
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatMoneyCoin = (num, fixed = 3) => {
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  if (numberMoney.indexOf(".000") > -1) {
    numberMoney = numberMoney.replace(".000", "");
  }
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatMoneyCoinInput = (num, fixed = 5) => {
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{5})+(?!\d))/g, "$1,");
  if (numberMoney.indexOf(".00000") > -1) {
    numberMoney = numberMoney.replace(".00000", "");
  }
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatNumberCoin = (num) => {
  if (!num) return 0;
  num = parseFloat(num).toFixed(8);
  return parseFloat(num).toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
};

export const SECRET_KEY = "pOWI7geiVlbr1aBRbBpbGYA02fsb7O";

export const upperCaseFirstString = (string) => {
  if (!string || string === "") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const blackListInputNumber = (e) => {
  // Allow: backspace, delete, tab, escape, enter and .
  if (
    [46, 8, 9, 27, 13, 110].includes(e.keyCode) ||
    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
      (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right, down, up
    (e.keyCode >= 35 && e.keyCode <= 40)
  ) {
    return true;
  }
  // Ensure that it is a number and stop the keypress
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    return false;
  }
  return true;
};

export const blackListInputNumberWithoutDot = (e) => {
  // Allow: backspace, delete, tab, escape, enter and .
  if (
    [46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
      (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right, down, up
    (e.keyCode >= 35 && e.keyCode <= 40)
  ) {
    return true;
  }
  // Ensure that it is a number and stop the keypress
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    return false;
  }
  return true;
};

export const apiUploadUrl = `${process.env.REACT_APP_API_URL}/uploads/`;

export const convertToSlug = (str) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
};
export const convertToSlugCategory = (str) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  // str = str.replace(/^-+|-+$/g, '');

  // return
  return str;
};

export const checkString = (str) => {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (str.match(format)) {
    return true;
  } else {
    return false;
  }
};

export const jsonToHtml = (jsonStr) => {
  // const obj = JSON.parse(jsonStr);

  let html = "";
  jsonStr["blocks"].forEach(function (block, index) {
    switch (block["type"]) {
      case "paragraph":
        html += "<p>" + (block["data"]["text"] || "") + "</p>";
        break;

      case "header":
        html +=
          "<h" +
          block["data"]["level"] +
          ">" +
          block["data"]["text"] +
          "</h" +
          block["data"]["level"] +
          ">";
        break;

      case "raw":
        html += block["data"]["html"];
        break;

      case "list":
        const lsType = block["data"]["style"] === "ordered" ? "ol" : "ul";
        html += "<" + lsType + ">";
        block["data"]["items"].forEach(function (item, index) {
          html += "<li>" + item + "</li>";
        });
        html += "</" + lsType + ">";
        break;

      case "code":
        html +=
          '<pre><code class="language-' +
          block["data"]["lang"] +
          '">' +
          block["data"]["code"] +
          "</code></pre>";
        break;

      case "image":
        html +=
          '<div class="img_pnl"><img src="' +
          block["data"]["file"]["url"] +
          '" /></div>';
        break;
      case "simpleImage":
        html +=
          '<div class="img_pnl"><img src="' +
          block["data"]["url"] +
          '" /></div>';
        break;
      case "embed":
        if (block["data"]["service"] === "youtube") {
          html +=
            '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" style="width:100%;height:500px" src="' +
            block["data"]["embed"] +
            '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        }
        break;

      default:
        break;
    }
  });
  return html;
};

export const jsonToText = (jsonStr) => {
  // const obj = JSON.parse(jsonStr);

  let html = "";
  jsonStr["blocks"].forEach(function (block, index) {
    switch (block["type"]) {
      case "paragraph":
        html += "" + block["data"]["text"] + `\n\n`;
        break;
      default:
        break;
    }
  });

  return html;
};

export const checkNoImg = (name, image) => {
  if (image) {
    return image;
  }
  if (name) {
    return `https://icotar.com/initials/${encodeURI(name)}.png?bg=06527E`;
  }
  return "https://icotar.com/avatar/craig.png?bg=06527E";
};

export const formatMoneyNormal = (text) => {
  return text.toLocaleString();
};

export const RoundNumber = (substract) => {
  let round = a(substract);
  let compareDown = Math.abs(round.value - Math.floor(round.value));
  let compareUp = Math.abs(round.value - Math.ceil(round.value));
  if (compareDown < Number.EPSILON * parseInt(round.multiply)) {
    return Math.floor(round.value) / round.multiply;
  }
  if (compareUp < Number.EPSILON * parseInt(round.multiply)) {
    return Math.ceil(round.value) / round.multiply;
  }
  return substract;
};
export const a = (substract) => {
  //lay nguyen substract
  if (substract < 1) {
    let s = substract.toString();
    let _s = s.split(".")[1];
    let multiply = "10";
    for (let s of _s) {
      if (s === "0") {
        multiply += "0";
      } else {
        break;
      }
    }
    let s_s = s * parseInt(multiply);
    return {
      value: s_s,
      multiply,
    };
  } else {
    return {
      value: substract,
      multiply: 1,
    };
  }
};

export const formatNumber = (n) => {
  if (!n) return 0;
  const isPositive = n > 0 ? true : false;
  n = n ? n.toString() : "";
  const n_v = n
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${isPositive ? '' : '-'}${n_v}`;
};

export const formatAllNumber = (n) => {
  n = n ? n.toString() : "";
  if (isNaN(n)) {
    return "";
  }
  return n;
};

export const formatGold = (num) => {
  if (!num || num === "") return "0";
  let str = num.toString();
  const idx_nega = str.indexOf("-");
  let nega = "";
  if (idx_nega !== -1) {
    str = str.replace("-", "");
    nega = "-";
  }
  const idx = str.indexOf(".");
  if (idx === -1) {
    let string = str.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return nega + string;
  } else {
    const pre = str.slice(0, idx);
    let aft = str.slice(idx + 1, idx + 6);

    let fix_pre = pre.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    let data = fix_pre;
    data = data.replace(/,/g, "*");
    data = data.replace(/\./g, ",");
    data = data.replace(/\*/g, ".");
    let sum = 0;
    for (let i = 0; i < aft.length; i++) {
      sum = sum + Number(aft[i]);
    }
    if (sum === 0) {
      aft = "";
    } else {
      aft = `,${aft}`;
    }
    data = nega + data + aft;
    if (data.indexOf(",00000") > -1) {
      data = data.replace(",00000", "");
    }
    return data;
  }
};
export const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
};

export const formatMoneyGoldPriceCrawl = (num, fixed = 3) => {
  num = num / 1000;
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  if (numberMoney.indexOf(".000") > -1) {
    numberMoney = numberMoney.replace(".000", "");
  }
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatMoneyGoldPriceMillion = (num, fixed = 2) => {
  num = num / 1000000;
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // if (numberMoney.indexOf(".000") > -1) {
  //   numberMoney = numberMoney.replace(".000", "");
  // }
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  //   numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatMoneyGoldPrice = (num, fixed = 2) => {
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // if (numberMoney.indexOf(".000") > -1) {
  //   numberMoney = numberMoney.replace(".000", "");
  // }
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  //   numberMoney = numberMoney.replace(/\./g, ",");
  numberMoney = numberMoney.replace(/\*/g, ".");
  return numberMoney;
};

export const formatMoneyGoldPriceRate = (num, fixed = 2) => {
  if (!num || num === "") return "0";
  let numberMoney = Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // if (numberMoney.indexOf(".000") > -1) {
  //   numberMoney = numberMoney.replace(".000", "");
  // }
  numberMoney = (numberMoney + "").replace(/,/g, "*");
  numberMoney = numberMoney.replace(/\./g, ".");
  numberMoney = numberMoney.replace(/\*/g, ",");
  return numberMoney;
};

export const formatCoinID = (amount, coin_id) => {

  switch (coin_id) {
    case 1:
      return formatMoney(amount) + " VNĐ";
    case 2:
      return formatGold(amount) + " CHỈ"
    case 3:
      return numberFormat(amount, 5, ',', '.') + " HNG";
    case 4:
      return numberFormat(amount, 5, ',', '.') + " USDT";
    case 5:
      return numberFormat(amount, 5, ',', '.') + " OUNCE";
    default:
      return formatMoneyCoin(amount) + " VNĐ"
  }
}

export const formatCoin = (amount, coin_id) => {

  switch (coin_id) {
    case 1:
      return formatMoney(amount);
    case 2:
      return formatGold(amount);
    case 3:
      return numberFormat(amount, 5, ',', '.');
    case 4:
      return numberFormat(amount, 5, ',', '.');
    case 5:
      return numberFormat(amount, 5, ',', '.');
    default:
      return numberFormat(amount, 5, ',', '.')
  }
}

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(6)
}

export const renderAgencyName = (level) => {
  switch (level) {
    case 1: return 'Đại sứ kinh doanh'
    case 2: return 'Nhượng quyền trực tuyến'
    default: return 'Người dùng'
  }
}

export const numberFormat = (number, decimals, decPoint, thousandsSep) => { // eslint-disable-line camelcase
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = ''
  const toFixedFix = function (n, prec) {
    if (('' + n).indexOf('e') === -1) {
      return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
    } else {
      const arr = ('' + n).split('e')
      let sig = ''
      if (+arr[1] + prec > 0) {
        sig = '+'
      }
      return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
    }
  }
  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    // s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  if (s[1] === '') {
    return s[0]
  }
  return s.join(dec)
}

export const formatXAU = (number) => {
  // Chia số thành phần hàng nghìn và hàng thập phân
  var parts = number.toString().split('.');
  var integerPart = parts[0];
  var decimalPart = parts.length > 1 ? parts[1] : '0';

  // Format phần hàng nghìn
  var formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Lấy chỉ một chữ số ở hàng thập phân
  var formattedDecimalPart = decimalPart.charAt(0);

  // Kết hợp phần hàng nghìn và hàng thập phân
  var formattedNumber = formattedIntegerPart + ',' + formattedDecimalPart;

  return formattedNumber;
}

export const formatterNumber = (value) => {
  // Remove non-numeric characters (except dot) and format the number
  if (!value) return '';
  const number = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return number;
};

export const parsererNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(4);
};


export const _convertHash = (hash) => {
  const string = hash ? hash.slice(0, 5) + "..." + hash.slice(-5) : ''
  return string
}

export {
  convertAnswer,
  removeSpaceString,
  formatWeight,
  LIMIT_PAGE,
  LIMIT_PAGE_MAX,
  LIMIT_PAGE_MODAL
}