import { Types } from './type'

const initialState = {
    predict: {},
    listPredict: {
        paging: {},
        rows: []
    }
}
const statisticReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_DATA_PREDICT:
            return {
                ...newState,
                predict: action.payload
            }
        case Types.SAVE_LIST_PREDICT:
            return {
                ...newState,
                listPredict: action.payload
            }
        default:
            return { ...newState }
    }
}
export default statisticReducer
