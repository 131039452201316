import { Types } from './types'

const initialState = {
    dataCampaign: [],
    dataTask: [],
}
const campaignReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.GET_CAMPAIGN:
            return {
                ...newState,
                dataCampaign: action.payload
            }
        case Types.GET_TASK_CAMPAIGN:
            return {
                ...newState,
                dataTask: action.payload
            }
        default:
            return { ...newState }
    }
}
export default campaignReducer
