import { Types } from './type'

const initialState = {
    listWithdraw: {
        paging: {},
        rows: []
    },
    listResult: {
        paging: {},
        rows: []
    },
    statistic: []
}
const spinReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_WITHDRAW:
            return {
                ...newState,
                listWithdraw: action.payload
            }
        case Types.SAVE_RESULT_SPIN:
            return {
                ...newState,
                listResult: action.payload
            }
        case Types.SAVE_SPIN_STATISTIC:
            return {
                ...newState,
                statistic: action.payload
            }
        default:
            return { ...newState }
    }
}
export default spinReducer
